<template>
  <div class="col-md-12 box my-2 align-items-center">
    <p class="descricao mb-0 mr-3">Observação</p>
    <input
      type="text"
      class="input_default form-control"
      :value="note"
      @input="$emit('update:note',$event.target.value)"
    />
    <i class="fas fa-times-circle ml-4 exclude" @click.prevent="$emit('showObs')"></i>
    <i class="fas fa-check-circle ml-3 edit" @click.prevent="newLog()"></i>
  </div>
</template>

<script>
import HTTP from "@/api_system";
export default {
  props: ["id", "note"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    newLog() {
      var json = { id: this.id, note: this.note };

      HTTP.put("services/app/LogBook/UpdateLogBook", json, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      }).then(response => {
        this.$emit("getLogs");
        $(".editItem").velocity("fadeOut", {
          display: "none",
          duration: 500
        });
        this.$emit("update:activeEdit", null);
        this.$notify({
          group: "erros",
          type: "sucess",
          text: `<i class="icon ion-close-circled"></i>Item criado`
        });
      });
    }
  }
};
</script>

<style scoped>
.box {
  background: #357eb8;
  border: 1px solid #e0a638;
  height: 75px;
}
.input_default {
  height: 45px;
  color: #ffffff;
  text-align: left;
  background-color: #6198c2 !important;
}
.descricao {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}
.fas,
.far {
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s linear;
}
.exclude {
  color: #e67979;
}
.edit {
  color: #35b890;
}
</style>